var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.bottomCornerButtonList.buttons, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            on: {
              mouseover: function ($event) {
                return _vm.mouseover(
                  _vm.bottomCornerButtonList.buttons[index].type
                )
              },
              mouseleave: function ($event) {
                return _vm.mouseleave(
                  _vm.bottomCornerButtonList.buttons[index].type
                )
              },
              click: function ($event) {
                _vm.bottomCornerButtonList.settings.showNextOnLastStep ==
                  true && _vm.isLastStep == true
                  ? _vm.goToNextPage()
                  : _vm.bottomCornerButtonList.buttons[index]
                      .numBerValueAfterClick !== undefined &&
                    _vm.bottomCornerButtonList.buttons[index]
                      .numBerValueAfterClick !== null
                  ? _vm.clickHandle(
                      _vm.bottomCornerButtonList.buttons[index]
                        .numBerValueAfterClick
                    )
                  : _vm.bottomCornerButtonList.buttons[index].repeatBtn
                  ? _vm.randomClick()
                  : null
              },
            },
          },
          [
            !(
              _vm.bottomCornerButtonList.settings.showNextOnLastStep == true &&
              _vm.isLastStep
            )
              ? _c(
                  "div",
                  {
                    staticClass: "pageBottomCornerButton",
                    class:
                      _vm.bottomCornerButtonList.settings.showNextOnLastStep ==
                        true && _vm.isLastStep == true
                        ? _vm.typeImages["next"].classname
                        : _vm.typeImages[
                            _vm.bottomCornerButtonList.buttons[index].type
                          ].classname,
                  },
                  [
                    _c("img", {
                      staticClass: "btn-page-corner",
                      class: {
                        isHidden: !(
                          ((_vm.numBers >=
                            _vm.bottomCornerButtonList.buttons[index]
                              .startNumberRange &&
                            _vm.numBers <=
                              _vm.bottomCornerButtonList.buttons[index]
                                .endNumberRange) ||
                            _vm.numBers == undefined) &&
                          _vm.typeImages[
                            _vm.bottomCornerButtonList.buttons[index].type
                          ].isHover == false
                        ),
                      },
                      attrs: {
                        src: _vm.typeImages[
                          _vm.bottomCornerButtonList.buttons[index].type
                        ].image,
                      },
                    }),
                    _c("img", {
                      staticClass: "btn-page-corner-hover",
                      class: {
                        isHidden: !(
                          ((_vm.numBers >=
                            _vm.bottomCornerButtonList.buttons[index]
                              .startNumberRange &&
                            _vm.numBers <=
                              _vm.bottomCornerButtonList.buttons[index]
                                .endNumberRange) ||
                            _vm.numBers == undefined) &&
                          _vm.typeImages[
                            _vm.bottomCornerButtonList.buttons[index].type
                          ].isHover == true
                        ),
                      },
                      attrs: {
                        src: _vm.typeImages[
                          _vm.bottomCornerButtonList.buttons[index].type
                        ].imageHover,
                        alt: "",
                      },
                    }),
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "pageBottomCornerButton",
                class: [
                  !(
                    _vm.bottomCornerButtonList.settings.showNextOnLastStep ==
                      true && _vm.isLastStep
                  )
                    ? "isHidden"
                    : null,
                  _vm.typeImages["next"].classname,
                ],
              },
              [
                _c("img", {
                  staticClass: "btn-page-corner",
                  class: {
                    isHidden: !(
                      _vm.isLastStep == true &&
                      _vm.typeImages[
                        _vm.bottomCornerButtonList.buttons[index].type
                      ].isHover == false
                    ),
                  },
                  attrs: { src: _vm.typeImages["next"].image },
                }),
                _c("img", {
                  staticClass: "btn-page-corner",
                  class: {
                    isHidden: !(
                      _vm.isLastStep == true &&
                      _vm.typeImages[
                        _vm.bottomCornerButtonList.buttons[index].type
                      ].isHover == true
                    ),
                  },
                  attrs: { src: _vm.typeImages["next"].imageHover },
                }),
              ]
            ),
          ]
        )
      }),
      (_vm.bottomCornerButtonList.buttons.length == 0 ||
        _vm.bottomCornerButtonList.buttons == undefined) &&
      _vm.bottomCornerButtonList.settings.showNextOnLastStep == true &&
      _vm.isLastStep == true
        ? _c(
            "div",
            {
              staticClass: "pageBottomCornerButton",
              class: [
                !(
                  _vm.bottomCornerButtonList.settings.showNextOnLastStep ==
                    true && _vm.isLastStep
                )
                  ? "isHidden"
                  : null,
                _vm.typeImages["next"].classname,
              ],
            },
            [
              _c("div", { staticClass: "img-container" }, [
                _c("img", {
                  staticClass: "btn-page-corner",
                  attrs: { src: _vm.typeImages["next"].image },
                }),
              ]),
            ]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }